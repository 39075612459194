import Footer from "../footer";
import Header from "../header";
import breadgrump from "../../images/breadcrumb_bg.jpg"
import bg from "../../images/backall.jpg"

const ContactComponent = () => {
    return(
        <>
            <Header/>
            <section className="mvpAboutTop">
                <div className="bg-cover h-auto p-5" style={{
            backgroundImage: `url(${breadgrump})`,
          }}>

                    <div className="container mx-auto md:p-10">
                         <h2 className="text-white text-3xl font-bold">Contact us</h2>
                    </div>

                </div>
            </section>
            <section className="mvpAbout bg-cover p-5 md:p-10" style={{
            backgroundImage: `url(${bg})`,
          }}>
                <div className="container mx-about md:px-20">
                    <div className="bg-white shadow-lg rounded-lg p-5">
                           
                           <div className="mvpMap">
                           <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3949.308452639893!2d77.4316089750091!3d8.171647591859392!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zOMKwMTAnMTcuOSJOIDc3wrAyNicwMy4xIkU!5e0!3m2!1sen!2sin!4v1706799376925!5m2!1sen!2sin" width="100%" height="250" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                           </div>

                           <div className="mvpMobile font-bold text-xl p-5">
                                Please call or whatsapp for more queries.
                           </div>
                           <div className="flex justify-center flex-wrap  md:space-x-5">
                                <span className="bg-red-700 text-white rounded font-bold w-full md:w-auto"> <a href="tel:+919486191919" className="block  px-10 py-3">Make Call to 9486191919</a></span>
                                <span className="bg-red-700 text-white rounded font-bold mt-5 md:mt-0 w-full md:w-auto"> <a href="tel:+919940707505" className="block  px-10 py-3">Make Call to 9940707505</a></span>
                                <span className="bg-green-700 text-white rounded font-bold mt-5 md:mt-0 w-full md:w-auto"><a className="block px-10 py-3" href="https://wa.me/919486191919?text=Hi,%20MVP%20Matrimony,%20I%20need%20your%20help%20to%20find%20my%20partner.%20-%20https://mvpmatrimony.com/" target="_blank">Whatsapp</a></span>
                           </div>
                            
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    )
}
export default ContactComponent;