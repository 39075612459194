import Footer from "../footer";
import Header from "../header";
import breadgrump from "../../images/breadcrumb_bg.jpg"
import bg from "../../images/backall.jpg"

const AboutComponent = () =>{
    return(
        <>
            <Header/>
            <section className="mvpAboutTop">
                <div className="bg-cover h-auto p-5" style={{
            backgroundImage: `url(${breadgrump})`,
          }}>

                    <div className="container mx-auto md:p-10">
                         <h2 className="text-white text-3xl font-bold">About MVP Matrimony</h2>
                    </div>

                </div>
            </section>
            <section className="mvpAbout bg-cover md:p-10" style={{
            backgroundImage: `url(${bg})`,
          }}>
                <div className="container mx-about p-5 md:px-20">
                    <div className="bg-white shadow-lg rounded-lg p-5">
                            <h1 className="text-center text-rose-900 font-bold text-xl">About Us</h1>
                            <div className="text-justify">
                            <p className="mt-5">
                            MVP Matrimony, or Matrimonial Service Center, is the equivalent of including a "dream partner" in your "memorable album of life." We take great pride in being the top matrimonial website in India, assisting individuals in finding their soul mate in a hassle-free manner. 
                            </p>
                            <p className="mt-5">
                            For us, what is a "marriage"? The joining of two bodies, souls, families, and customs is called marriage. It is a sacred connection formed by the determination to share a life of love, faith, and commitment through both joy and suffering. It is a significant life event that drastically alters one's circumstances. The idea of an ideal family and society might arise from a successful marriage.
                            </p>

                            <h3 className="mt-5 text-rose-700 font-bold">Our Vision</h3>
                            <p className="mt-0">
                            “Give our guests a smooth matchmaking experience without interfering with their security or privacy.”
                            </p>

                            <h3 className="mt-5 text-rose-700 font-bold">Our Mision</h3>
                            <p className="mt-0">
                            “Find soulmates for people in a very straightforward manner and support them till their goal of marriage becomes a reality.”
                            </p>

                            <h3 className="mt-5 text-rose-700 font-bold">Our Promise</h3>
                            <p className="mt-0">
                            Our visitors matter a lot to us at MVP Matrimony (Matrimonial Service Center), thus we want to make sure you are entirely satisfied with how we handle your personal data protection and storage as well as how we interact with you. Our first goal is to always ensure that you have solid reason to trust us. Verify our assurances, and don't be afraid to contact us if you need any clarification.
                            </p>
                            <ul className="p-5 list-disc">
                                <li className="ml-5">Protecting your personal information at all times</li>
                                <li className="ml-5">Never sell or swap your personal information</li>
                                <li className="ml-5">Contact you only when it is really needed</li>
                            </ul>
                            </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    )
}
export default AboutComponent;