import banners from '../../images/mainbg.png'
import couples from '../../images/couples.png'
import slider from '../../images/bg_slider.jpg'
import { Link } from 'react-router-dom'
const Banner = () => {
    return (
        <>
            <section className='mvpBanner'>
                <div className="bg-blue-300 bg-cover h-auto p-5" style={{
            backgroundImage: `url(${banners})`,
          }}>
                   <div className='container mx-auto md:px-10'>
                        <div className='flex items-center'>
                                <div className='text-center w-full md:w-1/2 md:text-left py-28'>
                                    {/* <img src={team}/> */}
                                    <h2 className='text-4xl font-bold mb-5 text-white'>Millions of Verified & Trusted Partners </h2>
                                    
                                    <p className='text-white font-bold pb-5 text-xl'>Never marry the one you can live with, Marry the one you cannot live without</p>
                                    
                                    <div className='flex justify-center md:justify-start'>

                                    <Link to='/contact' className='flex bg-red-800 p-2 rounded-lg text-white align-middle justify-center font-bold'>
                                    
                                        <span >Contact Us</span>
                                        
                                    </Link>
                                    </div>
                                </div>

                                <div className='hidden md:block w-1/4 ml-auto'>

                                    {/* <img src={couples}/> */}
                                    <img className='rounded-xl shadow-xl' src={slider}/>

                                    {/* <div className='border-2 bg-amber-300 rounded-lg'>
                                        <div className='text-center font-bold p-3 bg-amber-200 uppercase'>
                                            Register Now
                                        </div>
                                        <form>
                                            <input type='text'/>
                                        </form>
                                    </div> */}
                                </div>
                        </div>
                   </div>
                </div>
            </section>
            {/* <div className="md:container lg:container sm:container xl:container 2xl:container bg-cover h-96" style={{
            backgroundImage: `url(${banner})`,
          }}>
                Banner
            </div> */}
        </>
    );
}
export default Banner;