import logo from '../../images/logo.png'
import React, { useState } from "react";
import { Link } from 'react-router-dom';
const Header = () =>{
    const[isMenuVisible,setIsMenuVisible] = useState(false);
    return(
       <>
            {/* <div id="google_translate_element"></div> */}
            <section className="mvpHeader">
                <div className="bg-rose-700 p-5">
                    
                    <div className="container mx-auto md:px-10">
                        <div className="flex">
                            <div className="p-2">
                                <Link to="/"><img className='w-60 h-15' src={logo}/></Link>
                                {/* <span className="text-white font-extrabold rounded bg-red-400 p-2">MANIBHARATHI</span> */}
                            </div>
                            <div className='hidden w-2/5 md:flex justify-center items-center'>
                                <div className='text-left'>
                                <h2 className='text-white font-bold text-2xl'>A. Manickavasagam Pillai</h2>
                                <span className='text-white font-bold'>Founder</span>
                                </div>
                            </div>
                            <div className="ml-auto flex items-center">
                                <button className="lg:hidden" onClick={()=>setIsMenuVisible(!isMenuVisible)}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-white">
                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5" />
                                                        </svg>

                                </button>
                                <div className="hidden lg:block">
                                <ul className="flex text-white font-bold">
                                    <li className="p-2">
                                        <Link to="/" className="p-2 hover:border-2  border-white">Home</Link>
                                    </li>
                                    <li className="p-2">
                                    <Link to="/about" className="p-2 hover:border-2   border-white">About Us</Link>
                                    </li>
                                    <li className="p-2">
                                    <Link to="/profile" className="p-2 hover:border-2   border-white">Profiles</Link>
                                    </li>
                                    <li className="p-2">
                                    <Link to="/contact" className="p-2 text-rose-500 bg-white rounded shadow-slate-400">Contact Us</Link>
                                    </li>
                                </ul>
                                </div>
                            </div>
                        </div>

                        

                        {/* Mobile Menu */}
                        {
                            isMenuVisible && (
                                <div>
                                <ul className=" text-white font-bold mt-2 lg:hidden">
                                        <li className="p-2 mb-2">
                                            <Link to="/" className="p-2 hover:border-2  border-white">Home</Link>
                                        </li>
                                        <li className="p-2 mb-2">
                                        <Link to="/about" className="p-2 hover:border-2   border-white">About Us</Link>
                                        </li>
                                        <li className="p-2 mb-2">
                                        <Link to="/profile" className="p-2 hover:border-2   border-white">Profiles</Link>
                                        </li>
                                        <li className="p-2 mb-2">
                                        <Link to="/contact" className="p-2 bg-red-800 rounded shadow-slate-400">Contact Us</Link>
                                        </li>
                                    </ul>
                            </div>
                            )
                        }

                        <div className='text-white font-bold text-2xl border-t-2 p-2 md:hidden'>
                            <h3>A. Manickavasagam Pillai</h3>
                            <span>Founder</span>
                        </div>
                    </div>
                </div>
            </section>
       </>
    )
}
export default Header;