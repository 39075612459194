import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

window.SITE_URL = "https://mvpmatrimony.com/";
window.API_URL = "https://mvpmatrimony.com/mvpapi/public/api/";
window.IMG_URL = "https://mvpmatrimony.com/mvpapi/public/uploads/";

// window.SITE_URL = "http://localhost:3000/";
// window.API_URL = "http://localhost:8000/api/";
// window.IMG_URL = "http://localhost:8000/uploads/";'
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
