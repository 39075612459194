import logo from './logo.svg';
import './App.css';
import {BrowserRouter as Router, Routes, Route} from "react-router-dom"
import IndexComponent from './component/index';
import AboutComponent from './component/about';
import ContactComponent from './component/contact';
import ProfileComponent from './component/profile';


function App() {

  

  return (
    <div className="App">
      {/* <Router basename={'/sandbox'}> */}
      <Router>
        <Routes>
          <Route path="/" element ={<IndexComponent/>}/>
          <Route path="/about" element={<AboutComponent/>}/>
          <Route path="/profile" element={<ProfileComponent/>}/>
          <Route path="/contact" element={<ContactComponent/>}/>
        </Routes>
      </Router>
      </div>
  );
}

export default App;
