
import Header from '../header';
import Footer from '../footer';
import Home from '../body';
import Banner from '../banner';
import SocialComponent from '../social';
import useDocumentTitle from '../seo/useDocumentTitle';
import SeoComponent from '../seo/SeoComponent';

import { useEffect } from 'react';
const IndexComponent = () =>{
    const googleTranslateElementInit = () => {
        new window.google.translate.TranslateElement(
          {
            pageLanguage: "en",
            autoDisplay: false
          },
          "google_translate_element"
        );
      };
      useDocumentTitle("MVP Matrimony - Best Matrimonial Services in Nagercoil");
    
    //   useEffect(() => {
    //     var addScript = document.createElement("script");
    //     addScript.setAttribute(
    //       "src",
    //       "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    //     );
    //     document.body.appendChild(addScript);
    //     window.googleTranslateElementInit = googleTranslateElementInit;
    //   }, []);
    
      return (
          <>
            {/* <SeoComponent title="MVP Matrimony" keywords="matrimony,nagercoil,kanyakumari,best matrimony services,mvp matrimony,bride and groom service, marriage brocker" description="We MVPMatrimony.com are the leading matrimonial service provider in India. We will help you find the perfect life partner. Join us now and search among our thousands of members."/> */}
            <Header/>
            <Banner/>
            <Home/>
            <Footer/>
            <SocialComponent/>
          </>
      );
}
export default IndexComponent;