import Footer from "../footer";
import Header from "../header";
import breadgrump from "../../images/breadcrumb_bg.jpg"
import bg from "../../images/backall.jpg"
import { PaginationControl } from 'react-bootstrap-pagination-control';
import { Link } from "react-router-dom";
import profile from '../../images/profile.png'
import { useEffect,useState } from "react";
import SeoComponent from "../seo/SeoComponent";
const ProfileComponent = ()=>{
    const [profile,setProfile] = useState([])
    const [page,setPage] = useState(0);
    const[data,setData] = useState([]);

    function calculateAge(dateOfBirth) {
        let dateOfBirths = new Date(dateOfBirth);
        var today = new Date();
        var age = today.getFullYear() - dateOfBirths.getFullYear();
        var m = today.getMonth() - dateOfBirths.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < dateOfBirths.getDate())) {
          age--;
        }
        return age;
      }

    const getAllProfiles = (page) =>{
        //let url = "https://mvpmatrimony.com/mvpapi/public/api/match";
        let url = window.API_URL+"user/profile?page="+page;
        // var myHeaders = new Headers();
        // myHeaders.append("Authorization", 'Bearer '+localStorage.getItem('token'));
        fetch(url, {
          method: "get",
        })
        .then(response => response.json())
        .then(result => 
          {
            //setData(result.data)
            //setPage(result.data.current_page)
          
            //setProfile(result.data.data);
            setProfile(result.data);
            
        })
      }
  
      useEffect(() => {
        getAllProfiles(page)
      }, [page])
    return(
        <>
            {/* <SeoComponent title="MVP Matrimony" keywords="matrimony,nagercoil,kanyakumari,best matrimony services,mvp matrimony,bride and groom service, marriage brocker" description="We MVPMatrimony.com are the leading matrimonial service provider in India. We will help you find the perfect life partner. Join us now and search among our thousands of members."/> */}
            <Header/>
            <section className="mvpAboutTop">
                <div className="bg-cover h-auto p-5" style={{
            backgroundImage: `url(${breadgrump})`,
          }}>

                    <div className="container mx-auto md:p-10">
                         <h2 className="text-white text-3xl font-bold">Our Profiles</h2>
                    </div>

                </div>
            </section>
            <section className="mvpProfiles">
                <div className="container mx-auto md:p-10">
                    {
                      profile.length > 0 && (
                            <div>
                           {profile.map((profiles,index) => (
                            <div class="border rounded mt-5">
                            <div className="text-left p-5 bg-pink-200 font-bold text-xl">
                                <h2>{profiles.profile_name} - {profiles.profile_id}</h2>
                            </div>
                            <div className="md:flex my-auto p-5">
                            <div className='w-full md:w-1/4 flex flex-row md:flex-col justify-center items-center'>
                                    
                                    <div className="border rounded p-2">
                                            <img src={window.IMG_URL+profiles.profile_image} width="150px" height="150px"/>
                                    </div>
                                    {
                                        profiles.horoscope_image != null && (
                                            <div className="border rounded p-2">
                                            <img src={window.IMG_URL+profiles.horoscope_image} width="150px" height="150px"/>
                                            </div>
                                        )
                                    }
                                    
                            </div>
                            <div className='w-full md:w-2/4'>
                                    <table className="table-fixed font-bold w-full">
                                    <tbody>
                                        <tr>
                                            <td className="text-left md:w-1/2 px-4 py-2">
                                                Age:
                                            </td>
                                            <td className="text-right md:w-1/2 px-4 py-2">
                                                {/* 25 Years 1 Month */}
                                                {
                                                    calculateAge(profiles.date_of_birth)
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-left md:w-1/2 px-4 py-2">
                                                Star:
                                            </td>
                                            <td className="text-right md:w-1/2 px-4 py-2">
                                                {profiles.star}
                                               
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-left w-1/2 px-4 py-2">
                                                Religion:
                                            </td>
                                            <td className="text-right w-1/2 px-4 py-2">
                                            {profiles.religion}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-left w-1/2 px-4 py-2">
                                                Community:
                                            </td>
                                            <td className="text-right w-1/2 px-4 py-2">
                                            {profiles.community}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-left w-1/2 px-4 py-2">
                                                Caste:
                                            </td>
                                            <td className="text-right w-1/2 px-4 py-2">
                                            {profiles.caste}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-left w-1/2 px-4 py-2">
                                                Residence:
                                            </td>
                                            <td className="text-right w-1/2 px-4 py-2">
                                            {profiles.residence}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-left w-1/2 px-4 py-2">
                                                Native:
                                            </td>
                                            <td className="text-right w-1/2 px-4 py-2">
                                            {profiles.native}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-left w-1/2 px-4 py-2">
                                                Current Location:
                                            </td>
                                            <td className="text-right w-1/2 px-4 py-2">
                                            {profiles.current_location}
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>  
                            </div>
                            <div className='w-full md:w-2/4'>
                            <table className="table-fixed font-bold w-full">
                                        <tr>
                                            <td className="text-left w-1/2 px-4 py-2">
                                                Height:
                                            </td>
                                            <td className="text-right w-1/2 px-4 py-2">
                                            {profiles.height}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-left w-1/2 px-4 py-2">
                                                Weight:
                                            </td>
                                            <td className="text-right w-1/2 px-4 py-2">
                                            {profiles.weight}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-left w-1/2 px-4 py-2">
                                                Education:
                                            </td>
                                            <td className="text-right w-1/2 px-4 py-2">
                                            {profiles.education}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-left w-1/2 px-4 py-2">
                                                Occupation:
                                            </td>
                                            <td className="text-right w-1/2 px-4 py-2">
                                            {profiles.work}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-left w-1/2 px-4 py-2">
                                                Salary:
                                            </td>
                                            <td className="text-right w-1/2 px-4 py-2">
                                            &#8377; {profiles.salary}/Annum
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-left w-1/2 px-4 py-2">
                                                No. of Brothers:
                                            </td>
                                            <td className="text-right w-1/2 px-4 py-2">
                                            {profiles.no_of_brothers}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-left w-1/2 px-4 py-2">
                                                No. of Sisters:
                                            </td>
                                            <td className="text-right w-1/2 px-4 py-2">
                                            {profiles.no_of_sisters}
                                            </td>
                                        </tr>
                                    </table>   
                            </div>
                            </div>
                            <div className="flex justify-end p-4 bg-pink-200 font-bold text-xl">
                            <Link to="/contact"
                                                 className='flex justify-center bg-rose-900 px-5 py-2 text-white rounded-xl'>
                                                 <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-white mr-2">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                                            </svg>
                                                    <span>Get Details</span></Link>
                            </div>
                        </div>
                          ))}
                        </div>
                      )
                    }

                </div>

            </section>
        </>
    )
}
export default ProfileComponent;