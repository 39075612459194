import { Link } from "react-router-dom"
const SocialComponent = () =>{
    return(
        <>
        <a href="tel:+919486191919" id="callPhone" className="animate animated swing infinite"></a>
<a href="https://wa.me/919486191919?text=Hi,%20MVP%20Matrimony,%20I%20need%20your%20help%20to%20find%20my%20partner.%20-%20https://mvpmatrimony.com/ " id="callWhatsapp" target="_blank"></a>
        </>
        
    )
}
export default SocialComponent