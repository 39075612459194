import heart from '../../images/heart.png'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { Form } from 'formik';
import { Formik } from 'formik';
import LoadingComponent from '../loading';
import { useState } from 'react';

const Home = () => {
    const[isLoading,setLoading] = useState(false);
    const validate = values => {
        const errors = {};
    }

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/ 
      const emailRegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

    const formik = useFormik({
        initialValues:{
            iam:'',
            email:'',
            date_of_birth:'',
            caste:'',
            mobile:'',
        },
        validationSchema: Yup.object({
            iam:Yup.string().required('Required!'),
            caste:Yup.string().required('Required!'),
            date_of_birth:Yup.string().required('Required!'),
            mobile: Yup.string().required('Required').matches(phoneRegExp, 'Mobile Number is not valid').min(10).max(10),
            email:Yup.string().matches(emailRegExp,'Invalid email format!'),
          }),

          onSubmit: values => 
          {
            setLoading(true);
            const datas = new FormData();
            datas.append("iam", values.iam);
            datas.append("email", values.email);
            datas.append("mobile", values.mobile);
            datas.append("date_of_birth", values.date_of_birth);
            datas.append("caste", values.caste);
            let url = "https://mvpmatrimony.com/mvpapi/public/api/match";
            //let url = "http://localhost:8000/api/match";
            fetch(url,{
                "crossDomain": true,
                method: "POST",
                body: datas,
                "processData": false,
                "contentType": false,
            })
            .then(response => response.json())
            .then(data =>{
                setLoading(false);
                    if(data.success)
                    {
                        alert("Successfully Sent,Our Executive will be contact you soon!");
                        formik.values.caste = "";
                        formik.values.date_of_birth = "";
                        formik.values.mobile = "";
                        formik.values.email = "";
                        formik.values.iam = "";
                    }
                    else{
                        alert("Something went wrong! Please try again");
                    }
            })
            .catch(err => {
                setLoading(false);
                alert("Something went wrong! Please try again");
                  
            })
          },
          
    });

    

    

    return(
        <>
        <LoadingComponent isShow={isLoading}/>
            <section className="mvpBody">
                <div className="p-5">
                    <div className="container mx-auto md:px-10">
                        <div className="w-full md:w-1/2 mx-auto  border-b-rose-700 pb-5">
                            <h2 className=" text-rose-700 font-bold text-center text-2xl mb-5">Welcome to mvpmatrimony.com</h2>
                            <div className="flex justify-center pb-5">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-14 h-14 text-center text-rose-700">
  <path stroke-linecap="round" stroke-linejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z" />
</svg>

                            </div>
                            <p className="text-center text-black">
                            We MVPMatrimony.com are the leading matrimonial service provider in India. We will help you find the perfect life partner. Join us now and search among our thousands of members.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            
            <section className="mvpEnquiry bg-rose-700">
                <div className="p-5">
                    <div className="container mx-auto md:px-10">

                        <h2 className="text-white text-xl text-center">Find your true love here</h2>
                        <div className="flex justify-center">
                            
                            <img src={heart} className='w-40'/>

                        </div>
                        <div className="w-full md:w-4/5 mx-auto bg-white rounded p-5 mt-5">
                        <Formik
                            initialValues={formik.initialValues}
                            validationSchema={formik.validationSchema}
                            onSubmit={formik.handleSubmit}
                        >
                            <Form 
                            >
                                    <div className="text-center md:flex justify-center">
                                         <span className='font-semibold'>
                                            I am a
                                         </span>
                                         <p className="md:ml-5">

                                            <select id='iam' name='iam' className='border px-3 py-2'
                                             onChange={formik.handleChange}
                                             onBlur={formik.handleBlur}
                                             value={formik.values.iam}
                                            >
                                                <option value="">Please select who you are.</option>
                                                <option value="Groom">Groom</option>
                                                <option value="Bridal">Bridal</option>
                                            </select>
                                            {formik.touched.iam && formik.errors.iam ? (
                                                    <div className='text-red-900 font-bold'>{formik.errors.iam}</div>
                                                ) : null}
                                           
                                         </p>
                                    </div>
                                    <div className="md:grid md:grid-cols-2 md:gap-2 mt-5">
                                            
                                            <div className='mt-2 md:mt-0 text-left'>
                                                <label className='font-semibold'>
                                                    Date of Birth
                                                </label>
                                                <input 
                                                id='date_of_birth'
                                                name='date_of_birth'
                                                className="pl-2 w-full border-b-2 h-10 mt-2 outline-none rounded-lg focus:outline-gray-400" 
                                                placeholder="DOB" 
                                                type="date"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.date_of_birth}
                                                />
                                                {formik.touched.date_of_birth && formik.errors.date_of_birth ? (
                                                    <div className='text-red-900 font-bold'>{formik.errors.date_of_birth}</div>
                                                ) : null}
                                            </div>
                                   
                                            <div className='mt-2 md:mt-0 text-left'>
                                                <label className='font-semibold'>
                                                    Enter your caste
                                                </label>
                                                <input 
                                                id='caste'
                                                name='caste'
                                                className="pl-2 w-full border-b-2 h-10 mt-2 outline-none rounded-lg focus:outline-gray-400" 
                                                placeholder="Caste" 
                                                type="text"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.caste}/>
                                                {formik.touched.caste && formik.errors.caste ? (
                                                    <div className='text-red-900 font-bold'>{formik.errors.caste}</div>
                                                ) : null}
                                            </div>

                                            
                                    </div>   

                                    <div className="md:grid md:grid-cols-2 md:gap-2 mt-5">
                                            
                                            <div className='mt-2 md:mt-0 text-left'>
                                                <label className='font-semibold'>
                                                    Mobile Number
                                                </label>
                                                <input 
                                                id='mobile'
                                                name='mobile'
                                                maxLength={10}
                                                minLength={10}
                                                className="pl-2 w-full border-b-2 h-10 mt-2 outline-none rounded-lg focus:outline-gray-400" 
                                                placeholder="Contact/Whatsapp Number" 
                                                type="text"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.mobile}/>
                                                {formik.touched.mobile && formik.errors.mobile ? (
                                                    <div className='text-red-900 font-bold'>{formik.errors.mobile}</div>
                                                ) : null}
                                            </div>
                                   
                                            <div className='mt-2 md:mt-0 text-left'>
                                                <label className='font-semibold'>
                                                    Email ID
                                                </label>
                                                <input 
                                                id='email'
                                                name='email'
                                                className="pl-2 w-full border-b-2 h-10 mt-2 outline-none rounded-lg focus:outline-gray-400" 
                                                placeholder="Email Id" 
                                                type="text"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.email}/>
                                                {formik.touched.email && formik.errors.email ? (
                                                    <div className='text-red-900 font-bold'>{formik.errors.email}</div>
                                                ) : null}
                                            </div>

                                            
                                    </div>
                                    <div className='flex justify-center mt-5'>
                                    

                                         <button type='submit'
                                         className='flex justify-center bg-rose-900 px-5 py-2 text-white rounded-xl'>
                                         <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-white mr-2">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                                    </svg>
                                            <span>Get Profiles</span></button>
                                    </div>
                                   
                            </Form>
                            </Formik>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mvpWhy">
                <div className="p-5">
                    <div className="container mx-auto md:px-10">
                        
                    <h2 className=" text-rose-700 font-bold text-center text-2xl m-5">Why mvpmatrimony.com</h2>

<div className="flex flex-wrap bg-rose-100 rounded-lg mt-5 p-5">
    <div className="w-full md:w-1/3">
        <div className="bg-white p-5 m-2 md:m-5 rounded-lg shadow-xl">
            <p className="text-justify">
            The most fascinating experience in a person's life is marriage, which also determines whether they are happy or unhappy. One of the top matchmakers in India, MVP Matrimony assists you in establishing the ideal foundation. Happy marriages, in our opinion, start when you tie the knot with the person you love, and they continue to grow and prosper throughout time.
            </p>
        </div>
    </div>

    <div className="w-full md:w-1/3">
        <div className="bg-white p-5 m-2 md:m-5  rounded-lg shadow-xl">
            <p className="text-justify">
            Since innovation is at the core of what we do, we're always trying new methods to provide our members with the ideal fit. In this way, state-of-the-art technology is used to make your work and ours easier. If we are the reason for your happy marriage, where you feel like you are living every day in paradise, then we will be more satisfied.
            </p>
        </div>
    </div>

    <div className="w-full md:w-1/3">
        <div className="bg-white p-5 m-2 md:m-5  rounded-lg shadow-xl">
            <p className="text-justify">
            When you search online, you could find a lot of marriage-related websites, but we guarantee that no one else is as reliable as us. For us, the matrimony sector is more than just a job; it's our passion, which is how we have succeeded in becoming the greatest "Matchmaking for Marriage" in all of India's states.
            </p>
        </div>
    </div>
</div>
                    </div>
                </div>
            </section>



            <section className="mvpInfo">
                <div className="p-5 bg-purple-950">
                    <div className="container mx-auto md:px-10">
                        <div className="md:flex my-5">
                            <div className="mb-5 md:mb-0">
                                <p className="text-center md:text-start text-white font-light text-3xl">
                                Look through millions of profiles of suitable partners for marriage
                                </p>
                            </div>
                            <Link to="/contact" className="flex justify-center ml-auto text-purple-950 p-2 rounded bg-white font-bold">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                                    </svg>
                                <span>Contact Us</span>
                                </Link>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mpvMap">
            
            </section>

            <section className="mvpInfo">
                <div className="p-5 bg-rose-200">
                    <div className="container mx-auto md:px-10">
                    <h2 className=" text-rose-700 font-bold text-center text-2xl m-5">Why mvpmatrimony.com is Best matrimonial site?</h2>
                    <div className="rounded-lg bg-white shadow-xl p-5">
                        <p className="text-justify">
                        The older members of the family used to be the only ones responsible for setting up marriages or selecting suitable brides or grooms, and people from that generation tended to get married without reluctance. However, as generations have evolved and times have changed, so too have their ideas. Since our country now promotes individual independence, weddings in India do not occur without the approval of the children. Many things are becoming easier as the world moves toward digital media, but the biggest advantage for the current generation is that they can use matrimonial sites to locate the mate of their dreams. Having worked in the "Matrimony" industry for a number of years, we have recognized this need and are now providing millions of young people with dependable options via our website, mvpmatrimony.com. We are providing you with these verified profiles out of the same care that any parent would have to make sure their child finds a compatible partner: it is now our duty to demonstrate ideal matchmaking for marriage. 
                        </p>
                    </div>
                    </div>
                </div>
            </section>


        </>
    )
}
export default Home;