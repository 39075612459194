import logo from '../../images/logo.png'
import { Link } from 'react-router-dom';
const Footer = () =>{
    return(
        <>
            <section className="mvpFooter">
                <div className="bg-pink-600 text-white p-5">
                    <div className="container mx-auto md:px-10">
                        <div className="md:flex my-auto">
                            <div className="w-full md:w-1/4">
                                <img src={logo}/>
                            </div>
                            <div className='w-full md:w-1/4'>
                                <h3 className='text-center md:text-end border-b-2 pb-4 mb-4'>Quick Links</h3>
                                <ul className='text-center md:text-end'>
                                   <li><Link to="/about">About Us</Link></li>
                                   <li><Link to="/contact">Contact Us</Link></li>
                                </ul>
                            </div>
                            <div className='w-full mt-10 md:mt-0 md:w-2/5 ml-auto'>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3949.308452639893!2d77.4316089750091!3d8.171647591859392!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zOMKwMTAnMTcuOSJOIDc3wrAyNicwMy4xIkU!5e0!3m2!1sen!2sin!4v1706799376925!5m2!1sen!2sin" width="100%" height="250" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mvpTrueFooter">
                <div className="p-5">
                    <div className="container mx-auto text-center">
                        &copy; 2024 All Rights Reserved.
                    </div>
                </div>
            </section>
        </>
    )
}
export default Footer;